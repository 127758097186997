.custom-navbar {
    background-color: #ffffff;
  }
  
  .sidebar {
    width: 320px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
  }
  
  .sidebar::-webkit-scrollbar {
    width: 6px;
  }
  
  .sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 3px;
  }
  
  .sidebar::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  
  .content-wrapper {
    padding: 20px;
    margin: 20px;
  }
  
  .profile img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .profile h3 {
    margin: 10px 0;
  }
  
  .profile p {
    margin: 5px;
  }
  
  .menu-box {
    margin: 10px;
    padding: 10px;
    background: rgb(42,116,157);
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .menu-box a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
  }
  
  .menu-box a:hover {
    color: #ff0000;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  
  ul li a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
  }
  
  ul li a:hover {
    color: #ff0000;
  }
  .profile{
    margin: 10px;
  }
  .profile-photo {
    margin-left: 50px;
    position: relative;
    display: inline-block;
  }
  
  .photo-container {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .upload-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    height: 100%;
    width: 100%;
  }
  
  .profile-photo:hover .upload-overlay {
    opacity: 1;
  }
  .user-icon{
    font-size: 50px;
  }
  .upload-icon {
    color: #fff;
    font-size: 24px;
    cursor: pointer;
  }
  
  .upload-text {
    color: #fff;
    margin-top: 4px;
  }
  .logout {
    padding: 10px;
    border-radius: 5px;
    margin-top: auto; /* Mueve el enlace al final del navbar */
  }
  .white-icon svg {
    fill: white; /* Cambia el color de relleno del icono a blanco */
  }
  
  .logout-icon {
    margin-right: 5px;
    vertical-align: middle;
  }
  .arrow-icon {
    vertical-align: middle;
    margin-left: 5px;
    transition: transform 0.3s ease-in-out;
  }
  
  .arrow-icon.active {
    transform: rotate(180deg);
  }
  
  
  
  /* Estilos responsivos */
  @media screen and (max-width: 768px) {
    .sidebar {
      width: 100%;
      height: auto;
      position: static;
    }
    
    .content-wrapper {
      margin-left: 0;
    }
  }
  