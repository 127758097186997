.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: white;
  padding: 20px;
}

.App-logo {
  height: 50px;
  pointer-events: none;
}

.App-title {
  margin: 0;
}

.App-button {
  margin: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

.App-button:hover {
  cursor: pointer;
  background-color: #0056b3;
}

.App-main {
  flex: 1;
  display: flex;
  background-color: #f8f9fa;
}

.App-left {
  padding: 20px;
}

.App-right {
  padding: 20px;
}

.App-right p {
  text-align: justify;
}

.App-footer {
  background-color: white;
  padding: 20px;
}