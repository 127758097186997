.FirmaContrato{
  background: rgb(42,116,157);
}
.card-container {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background-color: aliceblue;
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

  .links-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  
  .links-list li {
    margin-bottom: 10px;
  }
  
  .signature-container {
    margin-top: 20px;
    text-align: center;
  }
  
  .accept-button {
    margin-top: 20px;
  }
  .terms-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .terms-popup-content {
    background-color: white;
    padding: 20px;
    max-width: 500px;
  }
  
  .close-terms-popup {
    margin-top: 10px;
  }

  
    