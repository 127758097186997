/* PQRListPage.css */

.form-container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .field-list {
    list-style: none;
    padding: 0;
  }
  
  .field-list li {
    margin-bottom: 10px;
  }
  
  label {
    display: inline-block;
    width: 150px;
    font-weight: bold;
  }
  
  span {
    display: inline-block;
    margin-left: 10px;
    color: #333;
  }
  
  .select-field {
    margin-top: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
