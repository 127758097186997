.whatsapp-logo {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 9999;
  }
  
  .whatsapp-logo img {
    width: 60px;
    height: 60px;
  }
  
  @media (max-width: 768px) {
    .whatsapp-logo img {
      width: 40px;
      height: 40px;
    }
  }
  