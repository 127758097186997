.custom-row {
    background-color: #fff;
    align-items: baseline;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
}
.add-icon-container {
    display: flex;
    align-items: center;
  }
  
  .add-icon {
    margin-top: 15px;
    font-size: 24px;
    color: blue;
    cursor: pointer;
    margin-left: 5px;
  }
  