.img-left-register {
  width: 50%;
  min-width: 50%; /* Establece aquí el ancho mínimo deseado */
  background: url('../images/Getcar\ pag-14.jpg') center;
  background-size: cover;
}


input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="date"],
input[type="url"],
input[type="textarea"],
input[type="time"]


 {
    width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ced4da;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1386px;
  }
}

