.Contrato{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.FirmaVirtual{
  background-color: rgb(255, 255, 255);
}
label{
  margin-right: 30px;
  margin-top: 30px;

}
legend{
  margin-top: 20px;
}
h3{
  margin-left: 45px;
  margin-top: 25px;
}

.btn-success{
  margin: 15px;
  padding: 10px;
}

.btn-danger{
  margin: 15px;
  padding: 10px;
}

.loader {
  /* Estilos para el contenedor de la animación */
  display: none; /* Inicialmente oculto */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;

  /* Estilos para la animación */
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
