.header_Servicio{
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f0f0f0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
}

.container-service{
  background-color: rgb(230, 178, 64);;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Modal{
  position: fixed;
}
/* Estilos para el formulario */
.custom-form {
    padding: 20px;
  }
  
  .custom-form input,
  .custom-form select,
  .custom-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  /* Estilo para el contenedor del checkbox */
.checkbox-container {
  display: flex;
  align-items: center;
}

/* Estilo para el checkbox en sí */
.checkbox-container input[type="checkbox"] {
  width: 1%; /* Ancho del checkbox */
  margin-right: 8px; /* Espacio a la derecha del checkbox */
}

/* Estilo para la etiqueta del checkbox */
.checkbox-container label {
  font-size: 16px; /* Tamaño de fuente de la etiqueta */
}

  /* Estilos para los botones */
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .submit-button,
  .cancel-button {
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-button {
    background-color: #4caf50;
    color: white;
  }
  
  .cancel-button {
    background-color: #ccc;
    color: #fff;
  }


  
  .react-calendar .react-calendar__tile {
    font-size: 16px;
    padding: 10px;
  }

/* Agregar una imagen de marca de agua de fondo al calendario */
.react-calendar {
  background-image: url("/src/images/icons/output-onlinepngtools\ \(1\).png"); /* Reemplaza 'ruta-relativa-de-tu-imagen.jpg' con la ruta relativa de tu imagen de marca de agua */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-size: 270px; /* Ajusta el tamaño de la imagen automáticamente */
  background-position: right bottom; /* Coloca la imagen en la parte inferior derecha */
  border: 0.5px solid #000; /* Establece un borde de 2 píxeles de ancho y color negro (puedes ajustar el color y el ancho según tus preferencias) */
  border-radius: 10px; /* Establece un radio de 10 píxeles para redondear las esquinas (ajusta este valor según tus preferencias) */
  font-family: cursive, sans-serif;
  font-style: italic;
  font-size: 18px;
  width: 1000px;
}

  /* Cambiar el tamaño de los días de la semana */
  .react-calendar__month-view__weekdays__weekday {
    font-size: 18px;
  }
  
  /* Cambiar el tamaño de los días del mes */
  .react-calendar__month-view__days__day {
    font-size: 15px;
    width: 100px; /* Cambia el ancho de cada día para que sea más grande */
    height: 100px; /* Cambia la altura de cada día para que sea más grande */
  }
  
  /* Cambiar el tamaño del botón de navegación del calendario */
  .react-calendar__navigation__label__labelText {
    font-size: 18px;
  }
  
   /* Estilos para dispositivos medianos y pequeños */
  @media screen and (max-width: 768px) {
    .react-calendar {
      font-size: 14px; /* Cambiar el tamaño de fuente para dispositivos medianos y pequeños */
      width: auto; /* Permitir que el calendario se ajuste al ancho de la pantalla */
    }
    .observation-button {
      font-size: 10px; /* Tamaño de fuente igual al de las pantallas grandes */
      /* Otros estilos para centrar el botón en dispositivos móviles */
    }
   .react-calendar__month-view__weekdays__weekday {
       font-size: 14px; /* Cambiar el tamaño de fuente para dispositivos medianos y pequeños */
    }
    .react-calendar__month-view__days__day {
      font-size: 12px; /* Cambiar el tamaño de fuente para dispositivos medianos y pequeños */
      width: 80px; /* Cambiar el ancho de los días para dispositivos medianos y pequeños */
      height: 80px; /* Cambiar la altura de los días para dispositivos medianos y pequeños */
    }
  
    .react-calendar__navigation__label__labelText {
      font-size: 14px; /* Cambiar el tamaño de fuente para dispositivos medianos y pequeños */
    }
  } 


  /* Cambiar el color de fondo de los días */
  .react-calendar .react-calendar__tile--active {
    background-color: #007bff; /* Cambia a tu color preferido */
    color: #fff; /* Cambia a tu color preferido */
  }
  .observation-button.yellow-bg {
    background-color: rgba(230, 177, 64, 0.603);
    margin-top: 2px;
    display: block; /* Hace que el botón ocupe todo el ancho disponible */
    margin: 0 auto; /* Centra el botón horizontalmente */
  }
  
/* Cambiar el color de fondo de los días pares */
.react-calendar .react-calendar__month-view__days__day:nth-child(even) {
  background-color: rgba(169, 169, 169, 0.20); /* Gris claro con transparencia */
  color: #000; /* Cambia a tu color de texto preferido */
}

/* Cambiar el color de fondo de los días impares */
.react-calendar .react-calendar__month-view__days__day:nth-child(odd) {
  background-color: rgba(0, 123, 255, 0.20); /* Azul claro con transparencia */
  color: #000; /* Cambia a tu color de texto preferido */
}
