#clients-table {
  display: none;
}

#loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9999;
}

#loader-wrapper img {
  width: 50px;
  height: 50px;
}

.Main-Admin{
  margin-top: 30px;
}