/* Perfil.css */

.profile-page {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto 20px;
  overflow: hidden;
}

.profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-info {
  text-align: center;
}

.profile-info h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.profile-info p {
  font-size: 16px;
  color: #555;
}

.profile-actions {
  margin-top: 20px;
}

.profile-actions button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.profile-actions button:hover {
  background-color: #0056b3;
}
