.top-section {
  color: #ffffff;
  background-color: rgb(42,116,157);
  padding: 10px;
}
.contact-info{
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-direction: row;
}
.contact-info p {
  margin: 0;
}

.social-media a {
  margin-right: 10px;
}
.header_Inicio{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f0f0f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
}

.header{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 120px;
}
.navbar {
  background-color: #ffffff;
}
.navbar-expand-lg .navbar-collapse{
  margin-top: 35px;
  margin-left: 50px;
}
.navbar-nav .nav-link {
  color: black;
  position: relative;
  text-decoration: none;
}

.navbar-nav .nav-link::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: black;
  visibility: hidden;
  transform: scaleX(0);
  transition: visibility 0s linear 0.2s, transform 0.2s ease-in-out;
}

.navbar-nav .nav-link:hover {
  color: rgb(230, 178, 64);
}

.navbar-nav .nav-link:hover::after {
  visibility: visible;
  transform: scaleX(1);
  transition-delay: 0s;
}

.logo_Inicio{
  height: 80px;
  width: 240px;
}

.logo {
  height: 140px;
  width: 350px;
  margin-top: 35px;
}

.titulo-inicio {
  margin-top: 35px;
  margin-left: 50px;
  font-size: 30px;
}
.btn-primary{
  background: rgb(42,116,157);
  margin: 15px;
  padding: 10px;
}

.navbar-scroll {
  transform: translateY(-100%);
}
/* Estilos para el main */
.main {
  display: flex;
  align-items: center;
}

.swiper {
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  height: 100%; /* Cambiado: ajusta la altura al 100% del contenedor */
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.imagen {
  width: auto;
  object-fit: cover;
}

/* Estilos responsivos */
@media screen and (max-width: 768px) {
  .swiper {
    height: auto; /* Cambiado: altura automática para adaptarse al contenido */
  }
}

@media screen and (max-width: 576px) {
  .swiper {
    height: auto; /* Cambiado: altura automática para adaptarse al contenido */
  }
}

.content-container {
  background: #e6b240;
}

.videos-section {
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.videos-section h2 {
  padding: 20px;
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
}

.videos-section .carousel {
  max-width: 100%;
}

.videos-section .carousel .slide {
  display: flex;
  justify-content: center;
}

.videos-section .carousel iframe {
  max-height: 400px;
}

.videos-section .carousel .control-arrow {
  font-size: 30px;
  color: #000;
  background-color: rgba(255, 255, 255, 0.5);
}

.videos-section .carousel .control-arrow:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.videos-section .carousel .control-dots .dot {
  background-color: #000;
}

.videos-section .carousel .control-dots .dot.selected {
  background-color: #fff;
  box-shadow: none;
}

@media screen and (max-width: 768px) {
  .videos-section .carousel iframe {
    max-height: 300px;
  }
}

/* Estilos para el footer */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital@1&display=swap');
*{
  font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
h2{
  margin-top: 5px;
  padding: 2px;
}
.pie-pagina{
    color: #fff;
    width: 100%;
    background: rgb(42,116,157);

}
.pie-pagina .grupo-1 {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 70px;
  padding: 45px 0px;
  align-items: end;
  justify-items: center;
  justify-content: end;
}

.pie-pagina .grupo-1 .box figure{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pie-pagina .grupo-1 .box figure img{
    width: 250px;
}
.pie-pagina .grupo-1 .box h2{
    color: #fff;
    margin-bottom: 25px;
    font-size: 20px;
}
.pie-pagina .grupo-1 .box p{
    margin-top: 10px;
    color: #efefef;
    margin-bottom: 10px;
}
.pie-pagina .grupo-1 .red-social a{
    display: inline-block;
    text-decoration: none;
    width: 45px;
    height: 40px;
    line-height: 45px;
    color: #fff;
    margin-right: 10px;
    text-align: center;
    transition: all 300ms ease;
}
.pie-pagina .grupo-2{
    background-color: #0a1a2a;
    padding: 15px 10px;
    text-align: center;
    color: #fff;
}
.pie-pagina .grupo-2 small{
    font-size: 15px;
}

@media screen and (max-width:800px){
    .pie-pagina .grupo-1{
        width: 90%;
        grid-template-columns: repeat(1, 1fr);
        grid-gap:30px;
        padding: 35px 0px;
    }
}
.footer {
  background: #000000;
  width: 100%;
  font-family: "Open Sans";
  padding-top: 40px;
  color: #fff;
}

.icon {
  color: #e6b240;
}

.box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.contact-info-footer {
  margin-bottom: 10px;
}

.red-social {
  display: flex;
}

.red-social a {
  margin-right: 10px;
}

.box1 {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Box2 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.footer-links {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 41px;
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.footer-links li {
  display: inline-block;
  margin-right: 10px;
}

.footer-links li a {
  text-decoration: none;
  color: #ffffff;
}

.footer-links a {
  color: black;
  position: relative;
  text-decoration: none;
}

.footer-links a::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: black;
  visibility: hidden;
  transform: scaleX(0);
  transition: visibility 0s linear 0.2s, transform 0.2s ease-in-out;
}

.footer-links a:hover {
  color: #e6b240;
}

.footer-links a:hover::after {
  visibility: visible;
  transform: scaleX(1);
  transition-delay: 0s;
}

.quienes-somos {
  margin-bottom: 45px;
}

.sliderFotter {
  width: 41vw;
  height: auto;
  margin: auto;
  overflow: hidden;
}

.sliderFotter .slide-track-Fotter {
  display: flex;
  animation: scroll 40s linear infinite;
  -webkit-animation: scroll 40s linear infinite;
  width: calc(100% * 14);
}

.sliderFotter .slideFotter {
  width: 200px;
}

.sliderFotter .slideFotter img {
  width: 100%;
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-200px * 7));
    transform: translateX(calc(-200px * 7));
  }
}

@media screen and (max-width: 768px) {
  .sliderFotter {
    max-width: 275px;
  }

  .sliderFotter .slide-track-Fotter {
    width: calc(100% * 14);
  }

  .sliderFotter .slideFotter {
    width: 150px;
  }
}


@media screen and (max-width: 768px) {
  .footer {
    padding-top: 20px;
  }

  .box {
    flex-direction: column;
    align-items: flex-start;
  }

  .Box2 {
    flex-direction: column;
    align-items: center;
  }

  .footer-links {
    margin-bottom: 20px;
  }

  .sliderFooter {
    width: 80%;
  }
}