.formularioRegistro{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.DatosDeEmpresaCliente{
    display: flex;
    flex-direction: column;
}
h4{
    margin-top: 20px;
}
.progress-bar {
  position: relative;
  width: 40%;
  height: 25px;
  background-color: #ccc;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 20px;
}

.progress-bar-fill {
  height: 100%;
  background-color: #007bff;
}

.cart-icon-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: left 3.5s ease-in-out;
}

.cart-icon {
  font-size: 24px;
  color: #000000;
}



.step-numbers {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.step-number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ccc;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-number.active {
  background-color: #007bff;
}

/* Estilos adicionales para hacerlo responsive */
@media (max-width: 768px) {
  .step-numbers {
    flex-wrap: wrap;
  }

  .step-number {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

  
  