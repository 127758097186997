
.App {
  font-family: Montserrat ;
  background: rgb(42,116,157);
background: -moz-linear-gradient(36deg, rgba(42,116,157,1) 0%, rgba(255,255,255,1) 70%, rgba(230,178,64,1) 100%);
background: -webkit-linear-gradient(36deg, rgba(42,116,157,1) 0%, rgba(255,255,255,1) 70%, rgba(230,178,64,1) 100%);
background: linear-gradient(36deg, rgba(42,116,157,1) 0%, rgba(255,255,255,1) 70%, rgba(230,178,64,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2a749d",endColorstr="#e6b240",GradientType=1);
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  height: 100%;
  width: 100%;
  ;
}
.wrapper {
  position: relative;
}

.content {
  position: absolute;
  top: 0;
  left: 320px; /* Ancho de la barra lateral + margen */
  right: 0;
  bottom: 0;
}

/* Estilos responsivos para tablets */
@media screen and (max-width: 1024px) {
  .content {
    left: 240px; /* Ancho de la barra lateral + margen para tablets */
  }
}

/* Estilos responsivos para dispositivos móviles */
@media screen and (max-width: 768px) {
  .content {
    position: relative;
    left: 0;
  }
}



