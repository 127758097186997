.Formularios{
  width: 100%;
  background: rgb(42,116,157);

}
.card {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background-color: #00ffff00;
    margin: 20px;
  }
  
  
  @media (min-width: 768px) {
    .card {
      margin-top: 2rem !important;
    }
  }
  
  